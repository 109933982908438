.school-card-img{
    
    
    transition: all 0.15s ease;    
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
    width: 180px;
    height: 180px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 100%;
}
.card-profile-div{
    width: 180px;
    height: 180px;
    background-color: white;
    transform: translate(-50%, -30%);
    left: 50%;
    position: absolute;
    border-radius: 100%;
}

.card-profile-div:hover{
    transform: translate(-50%, -33%);
}

.card-school-cover-img{
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.7);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

