

.custom-modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 10;
    min-height: 350vh;
    backdrop-filter: blur(5px);
}

.custom-modal-content {
    margin-top: 100px;
    position: relative;
    width: 100%;
    padding: 20px;
    z-index: 20;
    border-radius: 5px;
}